import { BookItem } from "../model/book-item.object";
import { Order } from "../model/order.object";
import { InvoiceTools } from "./invoice-tools";
import { ObligationTools } from "./obligation-tools";

// Tools for building order objects (used in more services)
export class OrderTools {
  // method for creating order objects from api objects
  static buildOrdersFromData(data: Array<any>): any {
    let orders: Array<Order> = [];
    data.forEach(
      o => {
        let order: Order = OrderTools.buildOrder(o);
        orders.push(order);
      }
    );
    return orders;
  }
  
  // method for creating a single invoice object
  static buildOrder(o: any): Order {
    if (o.obligations && o.obligations.length) {
      o.obligations = ObligationTools.buildObligationsFromData(o.obligations);
      o.obligations.forEach(
        (obligation: any) => {
          if (obligation.itinerary) {
            // keep just LOADINGS, UNLOADINGS, WAREHOUSE, TRANSSHIPMENT
            obligation.itinerary = obligation.itinerary.filter(
              (i: any) => i.type == 'L' || i.type == 'U' || i.type == 'V' || i.type == 'W' || i.type == 'T' || i.type == 'Z'
            );
          }
        }
      );
    }
    if (o.invoices && o.invoices.length) {
      o.invoices = InvoiceTools.buildInvoicesFromData(o.invoices);
    }
    if (o.order_comp_book) {
      let order_comp_book = new BookItem();
      for (let key in o.order_comp_book) {
        // @ts-ignore
        order_comp_book[key] = o.order_comp_book[key];
      }
      o.order_comp_book = order_comp_book;
    }

    let order: Order = new Order();
    for (let key in o) {
      // @ts-ignore
      order[key] = o[key];
    }
    return order;
  }
}