import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RecaptchaComponent } from "angular-google-recaptcha";

import { OrderService } from 'src/app/service/order.service';
import { ObligationService } from 'src/app/service/obligation.service';
import { Order } from 'src/app/model/order.object';
import { OrderTools } from 'src/app/tools/order-tools';
import { Obligation } from 'src/app/model/obligation.object';
import { LocalJsonService } from 'src/app/service/local-json.service';
import { Itinerary } from 'src/app/model/itinerary.object';
import { FilesService } from 'src/app/service/files.service';
import { AlertService } from 'src/app/service/alert.service';
import { SmsService } from 'src/app/service/sms.service';
import { WarehouseService } from 'src/app/service/warehouse.service';
import { ExchangeRatesService } from 'src/app/service/exchange-rates.service';

// @ts-ignore
import { PDF_QR_JS, PDF_JS } from 'pdf-qr';
import * as pdfjsLib from 'pdfjs-dist';
import { DateTools } from 'src/app/tools/date-tools';

@Component({
  selector: 'app-ext-order',
  templateUrl: './ext-order.component.html',
  styleUrls: ['./ext-order.component.css'],
  providers: [
    OrderService,
    ObligationService,
    LocalJsonService,
    ExchangeRatesService,
    FilesService,
    WarehouseService
  ]
})
export class ExtOrderComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];
  
  private _input_order_number: string | null = null;
  private _input_activate_nav: string | null = null;
  public get input_activate_nav(): string | null {
    return this._input_activate_nav;
  }
  
  private _input_token: string | null = null;
  public get input_token(): string | null {
    return this._input_token;
  }

  public order: Order = new Order();
  private _loadingOrder: boolean = false;
  public get loadingOrder(): boolean {
    return this._loadingOrder;
  }

  public dictionary: any = null;
  public country: string = 'CZ';

  public company: any = null;
  private _loadingCompany: boolean = false;
  public get loadingCompany(): boolean {
    return this._loadingCompany;
  }
  
  private _contacts: Array<any> = [];
  public get contacts(): Array<any> {
    return this._contacts;
  }

  private _persons: Array<any> = [];
  public get persons(): Array<any> {
    return this._persons;
  }

  private _contact_person: string | null = null;
  public get contact_person(): string | null {
    return this._contact_person;
  }

  private _contact_phone: string | null = null;
  public get contact_phone(): string | null {
    return this._contact_phone;
  }

  private _contact_email: string | null = null;
  public get contact_email(): string | null {
    return this._contact_email;
  }

  public logo: any = null;
  private _loadingLogo: boolean = false;
  public get loadingLogo(): boolean {
    return this._loadingLogo;
  }
  
  public settingsInvoicing: any = null;
  private _loadingSettingsInvoicing: boolean = false;
  public get loadingSettingsInvoicing(): boolean {
    return this._loadingSettingsInvoicing;
  }

  public settingsDocs: any = null;
  public color1: string = '#444444';
  public color2: string = '#0d6efd';
  private _loadingSettingsDocs: boolean = false;
  public get loadingSettingsDocs(): boolean {
    return this._loadingSettingsDocs;
  }
  
  public orderPdf: any = null;
  private _loadingOrderPdf: boolean = false;
  public get loadingOrderPdf(): boolean {
    return this._loadingOrderPdf;
  }

  public attachments: Array<any> = [];

  // forbidden -> invalid delegation (max 90 days)
  public accessForbidden: boolean = false;
  
  // order attributes
  public spz: string | null = null;
  public spz_trailer: string | null = null;
  public driver_name: string | null = null;
  public driver_phone: string | null = null;
  public additional_note: string | null = null;
  public accepted_by: string | null = null;

  get spz_valid(): boolean {
    return !this.spz || this.spz.length <= 14;
  }

  get spz_trailer_valid(): boolean {
    return !this.spz_trailer || this.spz_trailer.length <= 14;
  }

  get driver_name_valid(): boolean {
    return !this.driver_name || this.driver_name.length <= 100;
  }

  get driver_phone_valid(): boolean {
    return !this.driver_phone || (this.driver_phone.length >= 12 && this.driver_phone.length <= 13);
  }

  public attributesChanged: boolean = false;

  // true if ordered for company, that is also TA user
  public ourUserToOurUser: boolean = false;


  constructor(
    private _orderServ: OrderService,
    private _localJsonServ: LocalJsonService,
    private _obligationServ: ObligationService,
    private _filesServ: FilesService,
    private _exchangeRatesServ: ExchangeRatesService,
    private _warehouseServ: WarehouseService,
    private _smsServ: SmsService,
    private _alertServ: AlertService,
    private _route: ActivatedRoute,
    private _datePipe: DatePipe,
    private _title: Title
  ) { 
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
  }

  ngOnInit(): void {
    this._title.setTitle('Objednávka');
    this.loadData();
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  get loading(): boolean {
    return (
      this._loadingOrder || 
      this._loadingCompany || 
      this._loadingSettingsDocs || 
      this._loadingLogo || 
      this._loadingOrderPdf
    );
  }

  get validTokenOrder(): boolean {
    return this._input_token != null && this._input_order_number != null;
  }

  private loadData(): void {
    this._subscribed.push(
      // solve input query keys
      this._route.queryParams.subscribe(
        params => {
          if (!this._input_order_number) {
            this._input_order_number = params['order'] || null;
            if (this._input_order_number && this._input_order_number.startsWith('OB')) {
              this._input_order_number = this._input_order_number.slice(2);
            }
          }
          if (!this._input_token) {
            this._input_token = params['token'] || null;
          }
          if (this._input_order_number && this._input_token) {
            this.loadOrder();
          }
          if (this._input_token) {
            this.loadCompany();
            this.loadSettingsInvoicing();
            this.loadSettingsDocs();
            this.loadLogo();
            this.loadOrderPdf();
          }
        }
      ),
      this._localJsonServ.getOrderDictionary().subscribe({
        next: (response) => {
          this.dictionary = response;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert('Nepodařilo se načíst slovník.', 'danger', 4000);
        }
      })
    );
  }
  
  private loadOrder(): void {
    this.order = new Order();
    this.spz = null;
    this.spz_trailer = null;
    this.driver_name = null;
    this.driver_phone = null;
    this.additional_note = null;
    this.accepted_by = null;

    this._loadingOrder = true;

    let token = this._input_token;
    let order = this._input_order_number;

    this.attributesChanged = false;

    this._subscribed.push(
      // solve input query keys
      this._route.queryParams.subscribe(
        params => {
          // not handle when routing to new messages...
          if (params['nav']) {
            this._input_activate_nav = params['nav'] || null;
          }
        }
      ),
      this._orderServ.getExternalOrder(token, order).subscribe({
        next: (response) => {
          this.order = OrderTools.buildOrder(response);

          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(this.order);
          }

          // init local properties from loaded order
          this.spz = this.order.spz;
          this.spz_trailer = this.order.spz_trailer;
          this.driver_name = this.order.driver_name;
          this.driver_phone = this.order.driver_phone;
          this.accepted_by = this.order.accepted_by;
          if (this.order.note && this.order.note.includes('#')) {
            let idx: number = this.order.note.indexOf('#')
            this.additional_note = this.order.note.substring(idx, this.order.note.length);
          }
          if (this.order.order_comp_book && this.order.order_comp_book.country) {
            this.country = this.order.order_comp_book.country;
            if (!['CZ', 'SK', 'EN', 'DE', 'IT', 'ES', 'PL'].includes(this.country)) {
              // out of our dictionary - use EN
              this.country = 'EN';
            }
          }
          
          // init contact email and phone
          this.findPersonEmailPhone();

          this.attachments = [];
          if (this.order.obligations) {
            let itin_title: string = '';
            this.order.obligations.forEach(
              o => {
                // init attachments
                if (o.attachments) {
                  this.attachments = this.attachments.concat(o.attachments);
                }
                // init itinerary to title
                if (o.itinerary) {
                  o.itinerary.forEach(
                    (it: Itinerary) => {
                      if (it.place_city) {
                        itin_title += (itin_title != '' ? ' - ' : '');
                        itin_title += it.place_city;
                      }

                      // init warehouse itinerary flag according to order delivery_type
                      if (it.type == "W" || it.type == "V" || it.type == "T") {
                        if (this.order.delivery_type == "P") {
                          it.warehouse_in_itinerary = true;
                        }
                        else if (this.order.delivery_type == "D") {
                          it.warehouse_out_itinerary = true;
                        }
                      }
                    }
                  );
                }
              }
            );
            // set title from itinerary cities
            this._title.setTitle(itin_title);
          }
          // download thumbnails
          this.attachments.forEach(
            a => {
              this.downloadThumbnail(a);
            }
          )

          // load info - if both companies are TA users
          if (this.order.order_comp_book && this.order.order_comp_book.tin) {
            let tin = this.order.order_comp_book.tin;
            this._subscribed.push(
              this._orderServ.getExternalOrderTruckAgenda(token, order, tin).subscribe({
                next: (response) => {
                  if (response) {
                    this.ourUserToOurUser = true;
                  }
                },
                error: (err) => {
                  this.ourUserToOurUser = false;
                  console.log(err);
                }
              })
            );
          }

          // itinerary completing buttons lighting
          if (this.order.accepted_by && !this.allCompleted) {
            window.setInterval(
              () => {
                if (this._itineraryButtonBackground == 'btn-primary') {
                  this._itineraryButtonBackground = 'btn-outline-primary';
                }
                else {
                  this._itineraryButtonBackground = 'btn-primary';
                }
              }, 1500  
            );
          }

          if (this.order.accepted_by && 
            this.allCompleted && 
            this.attachments.length && 
            this.order.invoices && this.order.invoices.length
          ) {
            // open confirmation modal
            (<any>$('#alertSuccessModal')).modal('show');
            // hide after some timeout
            window.setTimeout(
              () => {
                (<any>$('#alertSuccessModal')).modal('hide');
              }, 7500
            );
          }

          // init message text
          window.setTimeout(
            () => {
              this.messageText = this.generateMessage();
            }, 1000
          );

          if (!this._input_activate_nav) {
            // already accepted, but itinerary not completed
            if (this.order.accepted_by && !this.allCompleted) {
              this._input_activate_nav = '4';
            }
            else if (this.order.accepted_by && this.allCompleted) {
              this._input_activate_nav = '5';
            }
          }

          // init invoice values
          this.initInvoiceDetail();

          // load warehouse events for piece shipment order
          if (this.order.delivery_type && this.order.itinerary && this.order.itinerary.length) {
            // load info about warehouse events
            let time_from_date: Date = new Date();
            // using first itinerary time
            let first: Itinerary = this.order.itinerary[0];
            if (first && first.arrival_time_date) {
              time_from_date = first.arrival_time_date;
            }
            // resize tf interval (without that some events could be missing)
            let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
            let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
            let filter_obj: any = {
              tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
              tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
              ok: this.order.order_key
            };

            this._subscribed.push(
              this._warehouseServ.getEvents(token, filter_obj).subscribe({
                next: (response) => {
                  if (response && response.length) {
                    this.order.warehouse_events = WarehouseService.buildEventsFromData(response);
                    console.log(this.order.warehouse_events);
                  }
                },
                error: (err) => {
                  console.log(err);
                }
              })
            );
          }


          // this._title.setTitle('Objednávka ' + this.order.order_number_standard);
          this._loadingOrder = false;
        },
        error: (err) => {
          console.log(err);
          // forbidden -> invalid access token
          if (err.status == 403) {
            this.accessForbidden = true;
          }
          this._alertServ.alert(this.dictionary['OrderGetError'][this.country], 'danger', 4000);
          this._loadingOrder = false;
        }
      })
    );
  }

  private loadCompany(): void {
    this._loadingCompany = true;
    this._subscribed.push(
      this._orderServ.getCompany(this._input_token, this._input_order_number).subscribe({
        next: (response) => {
          this.company = response;
          if (this.company) {
            if (this.company.persons) {
              this._persons = Object.values(this.company.persons);
              this._persons.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
            }
            if (this.company.contacts) {
              this._contacts = Object.values(this.company.contacts);
              this._contacts.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
            }

            // console.log(this._persons);
            // console.log(this._contacts);
            // init contact email and phone
            this.findPersonEmailPhone();
          }

          this._loadingCompany = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['CompanyGetError'][this.country], 'danger', 4000);
          this._loadingCompany = false;
        }
      })
    );
  }

  private loadSettingsInvoicing(): void {
    this._loadingSettingsInvoicing = true;
    this._subscribed.push(
      this._orderServ.getSettingsInvoicing(this._input_token, this._input_order_number).subscribe({
        next: (response) => {
          this.settingsInvoicing = response;
          this._loadingSettingsInvoicing = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['SettingsGetError'][this.country], 'danger', 4000);
          this._loadingSettingsInvoicing = false;
        }
      })
    );
  }

  private loadSettingsDocs(): void {
    this._loadingSettingsDocs = true;
    this._subscribed.push(
      this._orderServ.getSettingsDocs(this._input_token, this._input_order_number).subscribe({
        next: (response) => {
          this.settingsDocs = response;
          if (this.settingsDocs.presets && this.settingsDocs.presets.invoice_color1) {
            this.color1 = this.settingsDocs.presets.invoice_color1;
          }
          if (this.settingsDocs.presets && this.settingsDocs.presets.invoice_color2) {
            this.color2 = this.settingsDocs.presets.invoice_color2;
          }
          this._loadingSettingsDocs = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['SettingsGetError'][this.country], 'danger', 4000);
          this._loadingSettingsDocs = false;
        }
      })
    );
  }

  private loadLogo(): void {
    this._loadingLogo = true;
    this._subscribed.push(
      this._orderServ.getLogo(this._input_token, this._input_order_number).subscribe({
        next: (response) => {
          let newBlob: any = new Blob([response], { type: response.type });
          this.logo = {
            content: URL.createObjectURL(newBlob),
            type: response.type,
            blob: newBlob
          };
          this._loadingLogo = false;
        },
        error: (err) => {
          console.log(err);
          // this._alertServ.alert(this.dictionary['LogoGetError'][this.country], 'danger', 4000);
          this._loadingLogo = false;
        }
      })
    );
  }

  private loadOrderPdf(): void {
    this._loadingOrderPdf = true;
    this._subscribed.push(
      this._orderServ.getOrderPdf(this._input_token, this._input_order_number).subscribe({
        next: (response) => {
          let newBlob: any = new Blob([response], { type: response.type });
          this.orderPdf = {
            content: URL.createObjectURL(newBlob),
            type: response.type,
            blob: newBlob
          };
          this._loadingOrderPdf = false;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['OrderPdfGetError'][this.country], 'danger', 4000);
          this._loadingOrderPdf = false;
        }
      })
    );
  }

  // get default currency of our company
  get our_currency(): string {
    return (this.company && this.company.country == 'CZ') ? 'CZK' : 'EUR';
  }

  
  /***********************************************/
  /* Contacts */
  /***********************************************/
  findPersonEmailPhone(): void {
    this._contact_person = null;
    this._contact_email = null;
    this._contact_phone = null;
    if (this.order && this._contacts) {
      // order has some person_key
      if (this.order.person_key && this._persons) {
        let person = this._persons.find(p => p.person_key == this.order.person_key);
        if (person) {
          // set person name
          this._contact_person = person.name;

          // find email for person
          let email: any = this._contacts.find(
            x => x.importance == person.importance && x.type == 'E'
          );
          if (!email) {
            email = this._contacts.find(x => x.importance == person.importance && x.type == 'S');
          }
          if (!email) {
            email = this._contacts.find(x => x.importance == person.importance && x.type == 'U');
          }
          if (email) {
            this._contact_email = email.value;
          }
          else {
            // use first phone contact
            email = this._contacts.find(x => x.type == 'E');
            if (!email) { email = this._contacts.find(x => x.type == 'S'); }
            if (!email) { email = this._contacts.find(x => x.type == 'U'); }
            if (email) {
              this._contact_email = email.value;
            }
          }

          // find phone
          let phone: any = this._contacts.find(
            x => x.importance == person.importance && x.type == 'G'
          );
          if (!phone) {
            phone = this._contacts.find(x => x.importance == person.importance && x.type == 'T');
          }
          if (!phone) {
            phone = this._contacts.find(x => x.importance == person.importance && x.type == 'F');
          }
          
          if (phone) {
            this._contact_phone = phone.value;
          }
          else {
            // use first phone contact
            phone = this._contacts.find(x => x.type == 'G');
            if (!phone) { phone = this._contacts.find(x => x.type == 'T'); }
            if (!phone) { phone = this._contacts.find(x => x.type == 'F'); }
            if (phone) {
              this._contact_phone = phone.value;
            }
          }
        }
      }
      else {
        // no person - use first one (most important)
        let email = this._contacts.find(x => x.type == 'E');
        if (!email) { email = this._contacts.find(x => x.type == 'S'); }
        if (!email) { email = this._contacts.find(x => x.type == 'U'); }
        if (email) {
          this._contact_email = email.value;
        }

        // no person - use first one (most important)
        let phone = this._contacts.find(x => x.type == 'G');
        if (!phone) { phone = this._contacts.find(x => x.type == 'T'); }
        if (!phone) { phone = this._contacts.find(x => x.type == 'F'); }
        if (phone) {
          this._contact_phone = phone.value;
        }
      }
    }
  }


  /***********************************************/
  /* Customly downloading PDF of order */
  /***********************************************/
  get orderPdfName(): string {
    if (this.order && this.order.obligations && this.order.obligations.length) {
      let name: string = '';
      this.order.obligations.forEach(
        (o: Obligation) => {
          if (o.itinerary && o.itinerary.length) {
            o.itinerary.forEach(
              (it: Itinerary) => {
                if (it.place_city) {
                  name += it.place_city + '-';
                }
              }
            );
          }
        }
      );
      name += 'OB' + this.order.order_number_standard + '.pdf';
      return name;
    }
    return 'order.pdf';
  }

  // custom order download
  downloadOrderPdf(): void {
    if (this.orderPdf && this.orderPdf.blob) {
      // create <a> tag dynamically
      let fileLink = document.createElement('a');
      fileLink.href = URL.createObjectURL(this.orderPdf.blob);
      // it forces the name of the downloaded file
      fileLink.download = this.orderPdfName;
      // triggers the click event
      fileLink.click();
    }
  }


  /***********************************************/
  /* Updating order attributes */
  /***********************************************/
  public submittedConfirmation: boolean = false;

  openConfirmationModal(): void {
    this.submittedConfirmation = true;
    // if (this.spz && this.accepted_by) {
    if (this.accepted_by) {
      (<any>$('#confirmationModal')).modal('show');
    }
  }

  updateOrder(): void {
    // setup for order data updating
    let data: any = {};
    if (this.spz) { data.spz = this.spz; }
    if (this.spz_trailer) { data.spz_trailer = this.spz_trailer; }
    if (this.driver_name) { data.driver_name = this.driver_name; }
    if (this.driver_phone) { data.driver_phone = this.driver_phone; }

    if (this.additional_note) {
      if (this.order.note) {
        data.note = this.order.note + '\n# Výhrady a připomínky dopravce: ' + this.additional_note;
      }
      else {
        data.note = '# Výhrady a připomínky dopravce: ' + this.additional_note;
      }
    }

    if (this.accepted_by) {
      data.accepted_by = this.accepted_by;
    }
    else {
      data.accepted_by = 'Not Specified';
    }

    this._subscribed.push(
      this._orderServ.updateExternalOrder(
        this._input_token, 
        this._input_order_number, 
        this.country, 
        data
      ).subscribe({
        next: (response) => {
          this.order = OrderTools.buildOrder(response);
          this.attributesChanged = false;
          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(this.order);
          }
          this._alertServ.alert(this.dictionary['OrderPutOk'][this.country], 'success', 4000);

          
          // itinerary completing buttons lighting
          if (this.order.accepted_by && !this.allCompleted) {
            window.setInterval(
              () => {
                if (this._itineraryButtonBackground == 'btn-primary') {
                  this._itineraryButtonBackground = 'btn-outline-primary';
                }
                else {
                  this._itineraryButtonBackground = 'btn-primary';
                }
              }, 1500  
            );
          }
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['OrderPutError'][this.country], 'danger', 4000);
        }
      })
    );
  }

  additionalUpdateOrder(): void {
    // setup for order data updating - only 4 allowed properties
    let data: any = {};
    if (this.spz) { data.spz = this.spz; }
    if (this.spz_trailer) { data.spz_trailer = this.spz_trailer; }
    if (this.driver_name) { data.driver_name = this.driver_name; }
    if (this.driver_phone) { data.driver_phone = this.driver_phone; }

    this._subscribed.push(
      this._orderServ.updateExternalOrder(
        this._input_token, 
        this._input_order_number, 
        this.country, 
        data
      ).subscribe({
        next: (response) => {
          this.order = OrderTools.buildOrder(response);
          
          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(this.order);
          }
          this._alertServ.alert(this.dictionary['OrderPutOk'][this.country], 'success', 4000);
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['OrderPutError'][this.country], 'danger', 4000);
        }
      })
    );
  }


  /***********************************************/
  /* Obligation attachments part */
  /***********************************************/
  private _uploadingFile: boolean = false;
  public get uploadingFile(): boolean {
    return this._uploadingFile;
  }

  get externalAttachments(): Array<any> {
    // external origin + no origin (currently uploaded files)
    return this.attachments.filter(a => a.origin == 'EXTERNAL' || !a.origin);
  }

  checkAcceptedOrder(ev: any): void {
    // disable validation
    // if (!this.order.accepted_time) {
    //   (<any>$('#alertModal')).modal('show');
    //   // prevent opening file select
    //   ev.preventDefault();
    // }
  }

  droppedFile(event: any): void {
    // disable validation
    // if (!this.order.accepted_time) {
    //   (<any>$('#alertModal')).modal('show');
    //   return;
    // }

    if (event.target && event.target.files instanceof FileList) {
      let files = event.target.files;

      let token = this._input_token;
      let order = this.order.order_number_standard;
      let obligation: string | null = null; 
      if (this.order.obligations && this.order.obligations.length) {
        obligation = this.order.obligations[0].order_number_standard;
      }

      // set uploading file flag
      this._uploadingFile = true;

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        // possibly truncate long file names
        if (file.name && file.name.length > 150) {
          if (file.name.lastIndexOf('.') > 0) {
            let filename: string = file.name.substring(0, file.name.lastIndexOf('.'));
            let extension: string = file.name.substring(file.name.lastIndexOf('.'));
            
            const MAX_LENGTH: number = 140;
            file = new File([file], filename.substring(0, MAX_LENGTH) + extension, { type: file.type });
          }
        }

        // PDF/images/texts straight attachment upload
        if (file.type !== '' && (file.type.match(/image|pdf|text/))) {
          this._subscribed.push(
            this._orderServ.uploadAttachmentToObligation(token, order, obligation, file).subscribe({
              next: (response) => {
                // log only on localhost
                if (!window.location.href.match(/ext2.truckmanager.eu/)) {
                  console.log(response);
                }
                this._alertServ.alert(
                  file.name + ' ' + this.dictionary['FilePostOk'][this.country], 'success', 4000
                );

                // unset uploading file flag
                this._uploadingFile = false;
                // reload order
                this.loadOrder();
              },
              error: (err) => {
                console.log(err);
                this._alertServ.alert(
                  this.dictionary['FilePostError'][this.country] + ' ' + file.name, 'danger', 4000
                );
                // unset uploading file flag
                this._uploadingFile = false;
              }
            })
          );
        } 
        else {
          this._subscribed.push(
            this._filesServ.convertToPdf(file, this._input_token).subscribe(
              (pdfResponse: HttpResponse<any>) => {
                let newFile: File = new File(
                  [pdfResponse.body], 
                  file.name.substring(0, file.name.lastIndexOf('.')) + '.pdf'
                );

                this._subscribed.push(
                  this._orderServ.uploadAttachmentToObligation(token, order, obligation, newFile).subscribe({
                    next: (response) => {
                      // log only on localhost
                      if (!window.location.href.match(/ext2.truckmanager.eu/)) {
                        console.log(response);
                      }
                      this._alertServ.alert(
                        file.name + ' ' + this.dictionary['FilePostOk'][this.country], 'success', 4000
                      );
                      
                      // unset uploading file flag
                      this._uploadingFile = false;
                      // reload order
                      this.loadOrder();
                    },
                    error: (err) => {
                      console.log(err);
                      this._alertServ.alert(
                        this.dictionary['FilePostError'][this.country] + ' ' + file.name, 'danger', 4000
                      );
                      // unset uploading file flag
                      this._uploadingFile = false;
                    }
                  })
                );
              }
            )
          );
        }
        event.target.value = null;
      }
    }
  }

  downloadThumbnail(attachment: any): void {
    if (attachment.url && attachment.origin == 'EXTERNAL') {
      // let thumbUrl: string = '';
      // if (attachment.url.includes('/files/company/')) thumbUrl = attachment.url.replace('company/', 'company/thumb/');
      // else thumbUrl = attachment.url + '/thumb';
      let thumbUrl: string = '';
      if (attachment.url) {
        if (attachment.url.includes('?name')) {
          thumbUrl = attachment.url.substring(0, attachment.url.indexOf('?name'));
        }
        else {
          thumbUrl = attachment.url;
        }
      }
      if (thumbUrl) { 
        // add /thumb
        thumbUrl = thumbUrl.replace('company/', 'company/thumb/');
      }

      this._subscribed.push(
        this._filesServ.getThumbnail(thumbUrl, this._input_token).subscribe({
          next: (response) => {
            let newBlob: any = new Blob([response], { type: response.type });
    
            if (response.type !== '' && (response.type.match(/image|pdf|text/))) {
              attachment.thumbnail = {
                content: URL.createObjectURL(newBlob),
                type: response.type,
                blob: newBlob
              };
            }
          },
          error: (error) => {
            // set flag after error loading
            console.log(error);
          }
        })
      );
    }
  }

  downloadFile(attachment: any): void {
    if (attachment.url && attachment.origin == 'EXTERNAL') {
      let url: string = '';
      if (attachment.url) {
        if (attachment.url.includes('?name')) {
          url = attachment.url.substring(0, attachment.url.indexOf('?name'));
        }
        else {
          url = attachment.url;
        }
      }
      
      url = attachment.name;

      let token = this._input_token;
      let order = this.order.order_number_standard;
      let obligation: string | null = null; 
      if (this.order.obligations && this.order.obligations.length) {
        obligation = this.order.obligations[0].order_number_standard;
      }

      this._subscribed.push(
        this._orderServ.getAttachmentFromObligation(url, token, order, obligation).subscribe({
          next: (response) => {
            // console.log(response);
            if (response) {
              var newBlob = new Blob([response], { type: response.type ? response.type : "application/pdf" });

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && (navigator as any).msSaveOrOpenBlob) {
                (navigator as any).msSaveOrOpenBlob(newBlob);
                return;
              }
      
              // For other browsers: 
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob);
      
              // var link = document.createElement('a');
              // link.href = data;
              // link.download = attachment.name;
              // // this is necessary as link.click() does not work on the latest firefox
              // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      
              // setTimeout(function () {
              //     // For Firefox it is necessary to delay revoking the ObjectURL
              //     window.URL.revokeObjectURL(data);
              //     link.remove();
              // }, 100);
      
              // open new tab (does not work when ad-block is activated)
              window.open(data, '_blank');
            }
          },
          error: (error) => {
            // set flag after error loading
            console.log(error);
          }
        })
      );
    }
  }


  /***********************************************/
  /* Completing itinerary */
  /***********************************************/
  public itineraryToComplete: Itinerary | null = null;

  completeItineraryShow(it: Itinerary): void {
    if (it) {
      this.itineraryToComplete = it;
      (<any>$('#previewModal')).modal('hide');
      (<any>$('#completeItineraryModal')).modal('show');
    }
  }

  completed(it: Itinerary): void {
    if (this.itineraryToComplete) {
      this.itineraryToComplete.completed = true;
      // hotfix
      let itinerary = this.order.itinerary.find(i => i.itinerary_key == it?.itinerary_key);
      if (itinerary) {
        itinerary.completed = true;
      } 
      // hide modal
      (<any>$('#completeItineraryModal')).modal('hide');
    }
  }

  
  /***********************************************/
  /* Uploaded file from itinerary-diary */
  /***********************************************/
  uploadFileEvent(event: any): void {
    if (event) {
      // just reload order
      this.loadOrder();
    }
  }
  

  /***********************************************/
  /* Geolocation features */
  /***********************************************/
  closePreviewModal(event: any): void {
    if (event) {
      (<any>$('#previewModal')).modal('hide');
    }
  }
  
  public geolocationError: boolean = false;
  public geolocationString: string = '';

  geolocationErrorEvent(event: any): void {
    if (event == true) {
      this.geolocationError = true;
    }
    else if (event == false) {
      this.geolocationError = false;
    }
  }

  geolocationStringEvent(event: any): void {
    if (event) {
      this.geolocationString += this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm:ss') + ' - ';
      this.geolocationString += event;
      this.geolocationString += '<br><br>';
    }
  }

  openGeolocationModal(event: any): void {
    if (event) {
      (<any>$('#geolocationModal')).modal('show');
    }
  }


  /***********************************************/
  /* WhatsApp, Clipboard and Text generating */
  /***********************************************/
  // method for sending message using whatsapp web link
  sendWhatsAppMessage(): void {
    // check accepted
    if (!this.order.accepted_time) {
      (<any>$('#alertModal')).modal('show');
      return;
    }

    // this.messageText = this.generateMessage();
    let url: string = 'https://api.whatsapp.com/send';

    if (this.driver_phone) {
      url += '?phone=' + this.driver_phone;
      url += '&text=' + encodeURIComponent(this.messageText);
    }
    else {
      url += '?text=' + encodeURIComponent(this.messageText);
    }
    window.open(url, '_blank');
  }

  public messageText: string = '';

  // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  copyMessageToClipboard(): void {
    // check accepted
    if (!this.order.accepted_time) {
      (<any>$('#alertModal')).modal('show');
      return;
    }

    this.messageText = this.generateMessage();
    navigator.clipboard.writeText(this.messageText);
    this._alertServ.alert(this.dictionary['CopyText'][this.country], 'success', 3000);
  }

  // method for generating itinerary message for sending
  generateMessage(): string {
    let message: string = ''; // this.dictionary['DigitalniObjednavka'][this.country] + '!\n';

    message += 'Trasa: \n';
    if (this.order && this.order.obligations && this.order.obligations[0]) {
      let itinerary: Array<Itinerary> = this.order.obligations[0].itinerary; 

      let it_first: string = '';
      let it_last: string = '';

      if (itinerary && itinerary[0]) {
        it_first += itinerary[0].place_country + ' ';
        it_first += '*' + itinerary[0].place_city + '*';
      }
      if (itinerary && itinerary[itinerary.length - 1]) {
        it_last += itinerary[itinerary.length - 1].place_country + ' ';
        it_last += '*' + itinerary[itinerary.length - 1].place_city + '*';
      }

      message += it_first + ' - ';
      message += it_last + '\n';
    }

    message += this.dictionary['TruckAgendaDiář'][this.country] + '\n';
    message += 'https://ext2.truckmanager.eu/#/ext-itinerary-diary';
    message += '?order=' + this._input_order_number;
    message += '&token=' + this._input_token;

    return message;
  }

  generateMessageShort(): string {
    let message: string = 'Trasa: ';
    if (this.order && this.order.obligations && this.order.obligations[0]) {
      let itinerary: Array<Itinerary> = this.order.obligations[0].itinerary; 

      let it_first: string = '';
      let it_last: string = '';

      if (itinerary && itinerary[0]) {
        it_first += itinerary[0].place_country + ' ';
        it_first += itinerary[0].place_city;
      }
      if (itinerary && itinerary[itinerary.length - 1]) {
        it_last += itinerary[itinerary.length - 1].place_country + ' ';
        it_last += itinerary[itinerary.length - 1].place_city;
      }

      if (it_first.length > 15) {
        it_first = it_first.substring(0, 15);
      }
      if (it_last.length > 15) {
        it_last = it_last.substring(0, 15);
      }

      message += it_first + ' - ';
      message += it_last;
    }
    
    message += ' - https://ext2.truckmanager.eu/#/ext-itinerary-diary';
    message += '?order=' + this._input_order_number;
    message += '&token=' + this._input_token;

    return message;
  }

  
  /***********************************************/
  /* Scan images patterns */
  /***********************************************/
  public imgPatternSrc: string | null = null;

  openImgPattern(img_src: string | null): void {
    this.imgPatternSrc = img_src;
    (<any>$('#imgModal')).modal('show');
  }

  
  /***********************************************/
  /* Import to TruckAgenda */
  /***********************************************/
  importToTruckAgenda(): void {
    // just open app
    // let url: string = 'https://app2.truckmanager.eu/cs/#/(left:ta1-obligation/company_obligation)?reloadPossible=true';
    let url: string = 'https://app.truckmanager.eu/cs/#/(left:ta1-obligation/company_obligation)?reloadPossible=true';
    // let url: string = 'http://localhost:54538/cs/#/(left:ta1-obligation/company_obligation)?reloadPossible=true';
    url += '&order=' + this._input_order_number;
    url += '&token=' + this._input_token;
    if (this.company && this.company.tin) {
      url += '&tin=' + this.company.tin;
    }
    window.open(url, '_blank');
  }
  

  /***********************************************/
  /* External invoicing */
  /***********************************************/
  public invoiceVarSymbol: string | null = null;
  public invoiceConstantSymbol: string | null = '0308';
  public invoicePrice: number | null = null;
  public invoiceVatRate: number | null = null;
  public invoiceExchangeRate: number = 1;
  public invoiceVatHome: number | null = 0;
  public invoicePriceTotal: number | null = null;
  public invoiceCurrency: string | null = 'CZK';
  public invoicePaymentMethod: string | null = 'T';
  public invoiceFullfilment: string | null = null;
  public invoiceMaturity: string | null = null;
  public invoiceAccount: string | null = null;
  public invoiceIban: string | null = null;
  public invoiceSwift: string | null = null;
  public invoiceDescription: string | null = null;
  public invoiceFile: any = null;

  get orderInvoiced(): boolean {
    return (this.order && this.order.invoices && this.order.invoices.length > 0);
  }

  get orderMaturityDefined(): boolean {
    return (this.order && this.order.invoice_maturity != null);
  }

  get emptyRequiredInvoiceParams(): boolean {
    return (
      !this.invoiceVarSymbol || !this.invoicePriceTotal ||
      !this.invoiceCurrency || !this.invoiceMaturity || !this.invoiceIban
    );
  }

  // all itinerary have been completed
  get allCompleted(): boolean {
    // check if all itinerary are already completed
    let allCompleted: boolean = true;
    // find any not completed itinerary
    this.order.obligations.forEach(
      o => {
        if (o.itinerary) {
          o.itinerary.forEach(
            (it: Itinerary) => {
              if (!it.completed) {
                allCompleted = false;
              }
            }
          );
        }
      }
    );
    return allCompleted;
  }
  
  // show space after every 4th char in iban
  ibanFormatting(): void {
    if (this.invoiceIban) {
      this.invoiceIban = this.invoiceIban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    }
  }

  private _QR_missing: boolean = false;
  public get QR_missing(): boolean {
    return this._QR_missing;
  }

  // pdf attachment for view
  private _currentPdf: any;
  get currentPdf(): any {
    return this._currentPdf;
  }

  droppedInvoiceFile(event: any): void {
    // disable validation
    // if (!this.allCompleted) {
    //   (<any>$('#invoiceAlertModal')).modal('show');
    //   return;
    // }

    if (event.target && event.target.files instanceof FileList) {
      let files = event.target.files;
      if (files.length) {
        // console.log(files[0]);
        this.invoiceFile = files[0];

        if (this.invoiceFile.type) {
          console.log(this.invoiceFile.type);
          this._currentPdf = {
            content: URL.createObjectURL(this.invoiceFile),
            type: this.invoiceFile.type,
            name: this.invoiceFile.name
          };
        }

        // possibly truncate long file names
        if (this.invoiceFile.name && this.invoiceFile.name.length > 150) {
          if (this.invoiceFile.name.lastIndexOf('.') > 0) {
            let filename: string = this.invoiceFile.name.substring(0, this.invoiceFile.name.lastIndexOf('.'));
            let extension: string = this.invoiceFile.name.substring(this.invoiceFile.name.lastIndexOf('.'));
            
            const MAX_LENGTH: number = 140;
            this.invoiceFile = new File([this.invoiceFile], filename.substring(0, MAX_LENGTH) + extension, { type: this.invoiceFile.type });
          }
        }

        if (this.order) {
          // init price + currency
          this.invoicePrice = this.order.contracted_price;
          this.invoiceCurrency = this.order.currency;
          if (this.order.order_comp_book) {
            this.invoiceAccount = this.order.order_comp_book.account;
            this.invoiceIban = this.order.order_comp_book.iban;
            this.invoiceSwift = this.order.order_comp_book.swift;
            // formatting iban with spaces
            if (this.invoiceIban) {
              this.invoiceIban = this.invoiceIban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
            }
          }

          // init fullfilment
          if (this.order.obligations && this.order.obligations.length) {
            if (this.order.obligations[0].last_itinerary_time) {
              this.invoiceFullfilment = this._datePipe.transform(
                this.order.obligations[0].last_itinerary_time, 'yyyy-MM-dd'
              );
            }
          }

          // init maturity
          if (this.order.invoice_maturity) {
            // today + defined order maturity days
            let today: Date = new Date();
            let maturity_date: Date = new Date(today.getTime() + this.order.invoice_maturity*24 *60*60*1000);
            this.invoiceMaturity = this._datePipe.transform(maturity_date, 'yyyy-MM-dd');
          }

          // vat_rate init
          if (this.company && this.company.country == this.country && this.country == 'CZ') {
            // same country CZ -> vat_rate 21
            this.invoiceVatRate = 21;
          }
          else if (this.company && this.company.country == this.country && this.country == 'SK') {
            if (this.invoiceFullfilment) {
              let fullfilmentDate = new Date(this.invoiceFullfilment);
              if (fullfilmentDate && fullfilmentDate.getFullYear() < 2025) {
                this.invoiceVatRate = 20;
              }
              else {
                this.invoiceVatRate = 23;
              }
            }
          }
          else {
            // diff country -> vat_rate 0
            this.invoiceVatRate = 0;
          }
          this.changeInvoicePriceVat();

          // init exchange_rate and vat_home
          this.exchangeRateUpdate();


          // QR code parsing
          // this component ref
          let self = this;
          let configs = { // create and populate configs variable
            scale: {
              once: true,
              value: 2,
              start: 0.2,
              step: 0.2,
              stop: 2
            },
            resultOpts: {
              singleCodeInPage: true,
              multiCodesInPage: false,
              maxCodesInPage: 1
            },
            improve: true,
            jsQR: {}
          };
          let input_file = document.getElementById('pdfentryfile');
          // create callback which handles the result 
          let callback = function(result: any) {
            if (result) {
              // console.log(result);
              if (result.success) {
                if (result.codes && result.codes.length && result.codes[0]) {
                  self._QR_missing = false;
                  let qr_string: string = result.codes[0];
                  self.parseInvoiceQRcode(qr_string);
                }
                else {
                  self._QR_missing = true;
                  console.log('Nenalezen žádný QR kód');
                }
              }
              else {
                self._QR_missing = true;
                console.log(result.message);
              }
            }
          }
          // decode document (all pages)
          PDF_QR_JS.decodeDocument(input_file, configs, callback);
        }
      }
      
      (<any>$('#invoiceModal')).modal('show');
    }
  }

  private _QR_diff_price: boolean = false;
  public get QR_diff_price(): boolean {
    return this._QR_diff_price;
  }

  private _QR_diff_price_total: boolean = false;
  public get QR_diff_price_total(): boolean {
    return this._QR_diff_price_total;
  }

  private _QR_diff_maturity: boolean = false;
  public get QR_diff_maturity(): boolean {
    return this._QR_diff_maturity;
  }

  parseInvoiceQRcode(qr_string: string): void {
    console.log(qr_string);
    this._QR_diff_price = false;
    this._QR_diff_price_total = false;
    this._QR_diff_maturity = false;

    if (qr_string) {
      // parsing QR string
      let regex_currency = /(\*|%2A)CC:(.*?)(\*|\%|$)/;
      let match_currency = qr_string.match(regex_currency);
      if (match_currency && match_currency.length > 2) {
        if (match_currency[2]) {
          this.invoiceCurrency = match_currency[2];
        }
      } 

      let regex_iban = /(\*|%2A)ACC:(.*?)(\*|\%|$)/;
      let match_iban = qr_string.match(regex_iban);
      if (match_iban && match_iban.length > 2) {
        if (match_iban[2]) {
          this.invoiceIban = match_iban[2];
          let iban_swift_arr = match_iban[2].split('+'); 
          if (iban_swift_arr && iban_swift_arr.length == 2) {
            this.invoiceIban = iban_swift_arr[0];
            this.invoiceSwift = iban_swift_arr[1];
          }
        }
      } 

      let regex_price_total = /(\*|%2A)AM:(.*?)(\*|\%|$)/;
      let match_price_total = qr_string.match(regex_price_total);
      if (match_price_total && match_price_total.length > 2) {
        if (match_price_total[2]) {
          // check diff
          if (this.invoicePriceTotal != parseFloat(match_price_total[2])) {
            this._QR_diff_price_total = true;
          }
          this.invoicePriceTotal = parseFloat(match_price_total[2]);
        }
      } 

      let regex_exchange_rate = /(\*|%2A)FX:(.*?)(\*|\%|$)/;
      let match_exchange_rate = qr_string.match(regex_exchange_rate);
      if (match_exchange_rate && match_exchange_rate.length > 2) {
        if (match_exchange_rate[2]) {
          this.invoiceExchangeRate = parseFloat(match_exchange_rate[2]);
        }
      } 

      let regex_vat_home = /(\*|%2A)T0:(.*?)(\*|\%|$)/;
      let match_vat_home = qr_string.match(regex_vat_home);
      if (match_vat_home && match_vat_home.length > 2) {
        if (match_vat_home[2]) {
          this.invoiceVatHome = parseFloat(match_vat_home[2]);
        }
      } 

      let regex_variable_symbol = /(\*|%2A)X-VS:(.*?)(\*|\%|$)/;
      let match_variable_symbol = qr_string.match(regex_variable_symbol);
      if (match_variable_symbol && match_variable_symbol.length > 2) {
        if (match_variable_symbol[2]) {
          this.invoiceVarSymbol = match_variable_symbol[2];
        }
      } 

      let regex_maturity = /(\*|%2A)DT:(.*?)(\*|\%|$)/;
      let match_maturity = qr_string.match(regex_maturity);
      if (match_maturity && match_maturity.length > 2) {
        // QR date format: "yyyyMMdd"
        if (match_maturity[1] && match_maturity[2].length == 8) {
          let year: string = match_maturity[2].substring(0, 4);
          let month: string = match_maturity[2].substring(4, 6);
          let day: string = match_maturity[2].substring(6);

          let date_formatted: string = year + '-' + month + '-' + day;
          // check diff
          if (this.invoiceMaturity != date_formatted) {
            this._QR_diff_maturity = true;
          }

          this.invoiceMaturity = date_formatted;
        }
      } 

      let regex_fullfilment = /(\*|%2A)DUZP:(.*?)(\*|\%|$)/;
      let match_fullfilment = qr_string.match(regex_fullfilment);
      if (match_fullfilment && match_fullfilment.length > 2) {
        // QR date format: "yyyyMMdd"
        if (match_fullfilment[2] && match_fullfilment[2].length == 8) {
          let year: string = match_fullfilment[2].substring(0, 4);
          let month: string = match_fullfilment[2].substring(4, 6);
          let day: string = match_fullfilment[2].substring(6);

          let date_formatted: string = year + '-' + month + '-' + day;
          this.invoiceFullfilment = date_formatted;
        }
      }

      let regex_description = /(\*|%2A)MSG:(.*?)(\*|\%|$)/;
      let match_description = qr_string.match(regex_description);
      if (match_description && match_description.length > 2) {
        if (match_description[2]) {
          this.invoiceDescription = match_description[2];
        }
      } 

      // WORKS ONLY FOR INVOICES IN THE SAME CURRENCY
      // let regex_price = /\%2ATB0:(.*?)\%/;
      // let match_price = qr_string.match(regex_price);
      // if (match_price && match_price.length > 1) {
      //   if (match_price[1]) {
      //     // check diff
      //     if (this.invoicePrice != parseFloat(match_price[1])) {
      //       this._QR_diff_price = true;
      //     }

      //     this.invoicePrice = parseFloat(match_price[1]);
      //     // console.log(this.invoicePrice);
      //   }
      // } 
    }
  }

  exchangeRateUpdate(): void {
    // init exchange_rate and vat_home
    if (this.invoiceCurrency == this.our_currency) {
      this.invoiceExchangeRate = 1.00;
    }
    else {
      // // init exchange_rate
      // if (this.settingsInvoicing && this.settingsInvoicing.exchange_rates &&
      //   this.invoiceCurrency && this.settingsInvoicing.exchange_rates[this.invoiceCurrency]
      // ) {
      //   // use fixed exchange_rates
      //   this.invoiceExchangeRate = this.settingsInvoicing.exchange_rates[this.invoiceCurrency];
      // }
      // else if (this.settingsInvoicing && this.settingsInvoicing.first_day_of_month) {
      //   // use first_day_of_month
      //   let firstDay: Date = new Date();
      //   if (this.invoiceFullfilment) {
      //     firstDay = new Date(this.invoiceFullfilment);
      //   }
      //   firstDay.setDate(1);
      //   let date_for_rate = this._datePipe.transform(firstDay, 'yyyy-MM-dd');
      //   // @ts-ignore
      //   this._exchangeRatesServ.loadExchangeRates(this.company.country, this.invoiceCurrency, date_for_rate).subscribe(
      //     response => {
      //       if (response && response.length) {
      //         this.invoiceExchangeRate = response[0].rate;
      //         this.computeVatHome();
      //       }
      //     }
      //   );
      // }
      if (this.invoiceFullfilment) {
        // use fullfilment date
        let fullfilment_date: Date = new Date(this.invoiceFullfilment);
        let date_for_rate = this._datePipe.transform(fullfilment_date, 'yyyy-MM-dd');
        // @ts-ignore
        this._exchangeRatesServ.loadExchangeRates(this.company.country, this.invoiceCurrency, date_for_rate).subscribe(
          response => {
            if (response && response.length) {
              this.invoiceExchangeRate = response[0].rate;
              this.computeVatHome();
            }
          }
        );
      }
    }
    this.computeVatHome();
  }

  computeVatHome(): void {
    let vat: number = 0;
    if (this.invoicePriceTotal && this.invoicePrice) {
      vat = this.invoicePriceTotal - this.invoicePrice;
    }
    else if (this.invoicePrice && this.invoiceVatRate) {
      // multiply by vat rate
      vat = this.invoicePrice * this.invoiceVatRate;
    }

    // multiply by exchange rate
    if (this.invoiceExchangeRate) {
      vat = vat * this.invoiceExchangeRate;
    }

    if (this.invoiceExchangeRate) {
      this.invoiceVatHome = Math.round(vat * 100) / 100;
    }
  }

  changeInvoiceVatHome(): void {
    let price: number | null = this.invoicePrice;
    let price_total: number | null = this.invoicePriceTotal;
    if (price && price_total && this.invoiceVatHome) {
      // update exchange rate - ext user edited vat_home value
      this.invoiceExchangeRate = this.invoiceVatHome / (price_total - price);
      this.invoiceExchangeRate = Math.round(this.invoiceExchangeRate * 100) / 100;
    }
  }

  initInvoiceDetail(): void {
    if (this.orderInvoiced) {
      this.invoiceVarSymbol = this.order.invoices[0].variable_symbol;
      this.invoiceConstantSymbol = this.order.invoices[0].variable_symbol;
      this.invoicePrice = this.order.invoices[0].price;
      this.invoiceVatRate = this.order.invoices[0].vat1_rate;
      if (this.order.invoices[0].exchange_rate) {
        this.invoiceExchangeRate = this.order.invoices[0].exchange_rate;
      }
      this.invoiceVatHome = this.order.invoices[0].vat_home;
      this.invoicePriceTotal = this.order.invoices[0].price_total;
      this.invoiceCurrency = this.order.invoices[0].currency;
      this.invoicePaymentMethod = this.order.invoices[0].payment_method;
      this.invoiceFullfilment = this.order.invoices[0].fullfilment;
      this.invoiceMaturity = this.order.invoices[0].maturity;
      this.invoiceAccount = this.order.invoices[0].account;
      this.invoiceIban = this.order.invoices[0].iban;
      this.invoiceSwift = this.order.invoices[0].swift;
      this.invoiceDescription = this.order.invoices[0].description;
      // formatting iban with spaces
      if (this.invoiceIban) {
        this.invoiceIban = this.invoiceIban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      }
    }
  }

  showInvoiceDetail(): void {
    if (this.orderInvoiced) {
      this.initInvoiceDetail();

      (<any>$('#invoiceModal')).modal('show');
    }
  }

  // changed price or vat_rate
  changeInvoicePriceVat(): void {
    if ((this.invoicePrice || this.invoicePrice == 0) && (this.invoiceVatRate || this.invoiceVatRate == 0)) {
      // compute price_total
      this.invoicePriceTotal = this.invoicePrice + this.invoicePrice * (this.invoiceVatRate / 100);
      this.invoicePriceTotal = Math.round(this.invoicePriceTotal * 100) / 100;

      // compute vat_home
      this.computeVatHome();
    }
  }

  // changed price_total
  changeInvoicePriceTotal(): void {
    if ((this.invoicePrice) && this.invoicePriceTotal && this.invoicePriceTotal > this.invoicePrice) {
      // compute vat rate in decimal number
      this.invoiceVatRate = (this.invoicePriceTotal / this.invoicePrice) - 1;
      // convert to rounded percent
      this.invoiceVatRate = Math.round(this.invoiceVatRate * 100);
      
      // compute vat_home
      this.computeVatHome();
    }
  }

  createInvoice(): void {
    // formatting iban with spaces
    if (this.invoiceIban) {
      // remove whitespaces
      this.invoiceIban = this.invoiceIban.replace(/\s+/g, '');
      if (this.invoiceIban.length > 34) {
        this._alertServ.alert('IBAN může mít maximálně 34 znaků.', 'error', 4000);
        return;
      }
    }

    // setup for order data updating
    let data: any = {};
    if (this.invoiceVarSymbol) { data.received_invoice_no = this.invoiceVarSymbol; }
    if (this.invoiceVarSymbol) { data.variable_symbol = this.invoiceVarSymbol; }
    if (this.invoiceConstantSymbol) { data.constant_symbol = this.invoiceConstantSymbol; }
    if (this.invoicePrice) { data.price = this.invoicePrice; }
    if (this.invoicePriceTotal) { data.price_total = this.invoicePriceTotal; }
    if (this.invoiceVatRate || this.invoiceVatRate == 0) { data.vat1_rate = this.invoiceVatRate; }
    if (this.invoiceVatHome || this.invoiceVatHome == 0) { data.vat_home = this.invoiceVatHome; }
    if (this.invoicePrice && this.invoicePriceTotal) { data.vat1 = this.invoicePriceTotal - this.invoicePrice; }
    if (this.invoiceExchangeRate) { data.exchange_rate = this.invoiceExchangeRate }
    if (this.invoiceCurrency) { data.currency = this.invoiceCurrency; }
    if (this.invoicePaymentMethod) { data.payment_method = this.invoicePaymentMethod; }
    if (this.invoiceFullfilment) { data.fullfilment = this.invoiceFullfilment; }
    if (this.invoiceMaturity) { data.maturity = this.invoiceMaturity; }
    if (this.invoiceAccount) { data.account = this.invoiceAccount; }
    if (this.invoiceIban) { data.iban = this.invoiceIban; }
    if (this.invoiceSwift) { data.swift = this.invoiceSwift; }
    if (this.invoiceDescription) { data.description = this.invoiceDescription; }
    

    let today: Date = new Date();
    data.issued = this._datePipe.transform(today, 'yyyy-MM-dd');
    if (data.fullfilment) {
      let fullfilment_date: Date = new Date(data.fullfilment);
      data.year = fullfilment_date.getFullYear();
    }
    else {
      data.year = today.getFullYear();
    }

    let obligation: string | null = null;
    if (this.order.obligations && this.order.obligations.length) {
      obligation = this.order.obligations[0].order_number_standard;
    }

    this._subscribed.push(
      this._orderServ.createInvoice(
        this._input_token, 
        this._input_order_number, 
        obligation, 
        this.country,
        data, 
        this.invoiceFile
      ).subscribe({
        next: (response) => {
          // log only on localhost
          if (!window.location.href.match(/ext2.truckmanager.eu/)) {
            console.log(response);
          }
          // reload order
          this.loadOrder();
          this._alertServ.alert(this.dictionary['InvoicePostOk'][this.country], 'success', 4000);
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['InvoicePostError'][this.country], 'danger', 4000);
        }
      })
    );
  }


  /***********************************************/
  /* SMS sending */
  /***********************************************/
  public smsText: string = ''; 
  public smsPhone: string = '';
  
  get smsPhoneInvalid(): boolean {
    return !this.smsPhone || this.smsPhone.length < 12 || this.smsPhone.length > 13;
  }

  // using recaptcha angular stuff
  // https://www.npmjs.com/package/angular-google-recaptcha
  @ViewChild(RecaptchaComponent) recaptchaComponent: RecaptchaComponent;
  smsRecaptcha = new FormControl(false);

  get captchaChecked(): boolean {
    // https://github.com/JamesHenry/angular-google-recaptcha/issues/5
    const rc = (<unknown>this.recaptchaComponent) as any;
    return (rc && rc.recaptchaAPI && rc.recaptchaAPI.getResponse()) ? true : false;
  }


  initSMS(): void {
    // check accepted
    if (!this.order.accepted_time) {
      (<any>$('#alertModal')).modal('show');
      return;
    }

    // init phone
    if (this.driver_phone) {
      this.smsPhone = this.driver_phone;
    }

    // init sms text
    this.smsText = this.generateMessageShort();

    // open modal
    (<any>$('#smsModal')).modal('show');
  }

  // method for sending sms to drivers phone
  sendSMS(): void {
    let rc = (<unknown>this.recaptchaComponent) as any;
    let response = rc.recaptchaAPI ? rc.recaptchaAPI.getResponse() : null;
    if (response) {
      this._smsServ.createSMS(this.smsText, this.smsPhone, response).subscribe({
        next: (response) => {
          console.log(response);
          this._alertServ.alert(this.dictionary['smsOk'][this.country], 'success', 4000);
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert(this.dictionary['smsError'][this.country], 'danger', 4000);
        }
      });

      // reset recaptcha
      const rc = (<unknown>this.recaptchaComponent) as any;
      rc.recaptchaAPI.reset();
    }
    else {
      console.log('Info SMS has not been sent (no recaptcha).');
    }

    // save also phone number (if there has not been)
    if (!this.driver_phone) {
      this.driver_phone = this.smsPhone;
      this.additionalUpdateOrder();
    }
  }


  /***********************************************/
  /* Modal managing */
  /***********************************************/
  openImportModal(): void {
    if (!this.order.accepted_time) {
      (<any>$('#alertModal')).modal('show');
      return;
    }
    (<any>$('#importModal')).modal('show');
  }
  
  openPreviewModal(): void {
    if (!this.order.accepted_time) {
      (<any>$('#alertModal')).modal('show');
      return;
    }
    (<any>$('#previewModal')).modal('show');
  }

  openCompleteItineraryModal(): void {
    // disable validation
    // if (!this.order.accepted_time) {
    //   (<any>$('#alertModal')).modal('show');
    //   return;
    // }
    (<any>$('#completeItineraryModal')).modal('show');
  }

  checkCompletingItinerary(ev: any): void {
    // disable validation
    // if (!this.allCompleted) {
    //   (<any>$('#invoiceAlertModal')).modal('show');
    //   // prevent opening file select
    //   ev.preventDefault();
    // }
  }


  private _itineraryButtonBackground: string = 'btn-primary';
  get itineraryButtonBackground(): string {
    return this._itineraryButtonBackground;
  }
}
