import { DateTools } from "../tools/date-tools";
import { GoogleMapMarker } from "./google-map-marker.object";
import { Obligation } from "./obligation.object";

export class Itinerary {
  private _itinerary_key: number | null = null;
  get itinerary_key(): number | null {
    return this._itinerary_key;
  }
  set itinerary_key(value: number | null) {
    this._itinerary_key = value;
  }

  private _address: string | null = null;
  get address(): string | null {
    return this._address;
  }
  set address(value: string | null) {
    this._address = value;
  }

  private _address_excerpt: string | null = null;
  get address_excerpt(): string | null {
    return this._address_excerpt;
  }
  set address_excerpt(value: string | null) {
    this._address_excerpt = value;
  }

  // address components
  private _place_name: string | null = null;
  public get place_name(): string | null {
    return this._place_name;
  }
  public set place_name(value: string | null) {
    this._place_name = value;
  }
  
  private _place_city: string | null = null;
  public get place_city(): string | null {
    return this._place_city;
  }
  public set place_city(value: string | null) {
    this._place_city = value;
  }
  
  private _place_country: string | null = null;
  public get place_country(): string | null {
    return this._place_country;
  }
  public set place_country(value: string | null) {
    this._place_country = value;
  }
  
  private _place_street: string | null = null;
  public get place_street(): string | null {
    return this._place_street;
  }
  public set place_street(value: string | null) {
    this._place_street = value;
  }
  
  private _place_zip: string | null = null;
  public get place_zip(): string | null {
    return this._place_zip;
  }
  public set place_zip(value: string | null) {
    this._place_zip = value;
  }

  private _adr: string | null = null;
  get adr(): string | null {
    return this._adr;
  }
  set adr(value: string | null) {
    this._adr = value;
  }

  private _arrival_time: string | null = null;
  get arrival_time(): string | null {
    return this._arrival_time;
  }
  set arrival_time(value) {
    this._arrival_time = value;
    if (value) {
      this._arrival_time_date = DateTools.isoFix(value);
      // @ts-ignore
      this._arrival_time_custom = DateTools.isoForDatetimeInput(this._arrival_time_date);
    }
  }

  private _arrival_time_custom: string | null = null;
  public get arrival_time_custom(): string | null {
    return this._arrival_time_custom;
  }
  public set arrival_time_custom(value: string | null) {
    this._arrival_time_custom = value;
  }

  private _arrival_time_date: Date | null = null;
  public get arrival_time_date(): Date | null {
    return this._arrival_time_date;
  }
  public set arrival_time_date(value: Date | null) {
    this._arrival_time_date = value;
  }

  private _currency: string | null = null;
  get currency(): string | null {
    return this._currency;
  }
  set currency(value: string | null) {
    this._currency = value;
  }
  
  private _code: string;
  public get code(): string {
    return this._code;
  }
  public set code(value: string) {
    this._code = value;
  }
  
  private _destination: string;
  public get destination(): string {
    return this._destination;
  }
  public set destination(value: string) {
    this._destination = value;
  }

  private _googleAutocompleter: any;
  public get googleAutocompleter(): any {
    return this._googleAutocompleter;
  }
  public set googleAutocompleter(value: any) {
    this._googleAutocompleter = value;
  }

  private _gps_coord: string | null = null;
  get gps_coord(): string | null {
    return this._gps_coord;
  }
  set gps_coord(value: string | null) {
    this._gps_coord = value;
    if (this._gps_coord) {
      this._gps_coord_normalized = this._gps_coord.substring(1, this._gps_coord.length - 1);
      
      this._gps_coord_google = 'https://www.google.com/maps/dir//';
      this._gps_coord_google += this._gps_coord.replace('(', '').replace(')', '');
      this._gps_coord_google += '/?sensor=true\n';
    }
  }
  
  // custom - without brackets
  private _gps_coord_normalized: string | null = null;
  public get gps_coord_normalized(): string | null {
    return this._gps_coord_normalized;
  }
  public set gps_coord_normalized(value: string | null) {
    this._gps_coord_normalized = value;
  }

  // custom for google navigation - without brackets
  private _gps_coord_google: string | null = null;
  public get gps_coord_google(): string | null {
    return this._gps_coord_google;
  }
  public set gps_coord_google(value: string | null) {
    this._gps_coord_google = value;
  }

  private _completed: boolean | null = null;
  public get completed(): boolean | null {
    return this._completed;
  }
  public set completed(value: boolean | null) {
    this._completed = value;
  }

  private _length: number | null = null;
  get length(): number | null {
    return this._length;
  }
  set length(value: number | null) {
    this._length = value;
  }

  private _line_key: number | null = null;
  get line_key(): number | null {
    return this._line_key;
  }
  set line_key(value: number | null) {
    this._line_key = value;
  }

  private _loading_at_weekend: boolean | null = null;
  get loading_at_weekend(): boolean | null {
    return this._loading_at_weekend;
  }
  set loading_at_weekend(value: boolean | null) {
    this._loading_at_weekend = value;
  }

  private _loading_at_work_days: boolean | null = null;
  get loading_at_work_days(): boolean | null {
    return this._loading_at_work_days;
  }
  set loading_at_work_days(value: boolean | null) {
    this._loading_at_work_days = value;
  }

  private _loading_overtime_fine: number | null = null;
  get loading_overtime_fine(): number | null {
    return this._loading_overtime_fine;
  }
  set loading_overtime_fine(value: number | null) {
    this._loading_overtime_fine = value;
  }

  // limit is 50min by default
  private _loading_time_limit: number | null = 50;
  get loading_time_limit(): number | null {
    return this._loading_time_limit;
  }
  set loading_time_limit(value: number | null) {
    this._loading_time_limit = value;
  }

  private _loading_time_real: number | null = null;
  public get loading_time_real(): number | null {
    return this._loading_time_real;
  }
  public set loading_time_real(value: number | null) {
    this._loading_time_real = value;
  }
  
  private _note: string | null = null;
  get note(): string | null {
    return this._note;
  }
  set note(value: string | null) {
    this._note = value;
  }
  
  private _notify_arriving: string | null = null;
  public get notify_arriving(): string | null {
    return this._notify_arriving;
  }
  public set notify_arriving(value: string | null) {
    this._notify_arriving = value;
  }

  private _notify_completed: any;
  public get notify_completed(): any {
    return this._notify_completed;
  }
  public set notify_completed(value: any) {
    this._notify_completed = value;
  }

  private _obligation_key: number | null = null;
  get obligation_key(): number | null {
    return this._obligation_key;
  }
  set obligation_key(value: number | null) {
    this._obligation_key = value;
  }

  private _obligation: Obligation = new Obligation();
  public get obligation(): Obligation {
    return this._obligation;
  }
  public set obligation(value: Obligation) {
    this._obligation = value;
  }

  private _period_length_secs: number | null = null;
  get period_length_secs(): number | null {
    return this._period_length_secs;
  }
  set period_length_secs(value: number | null) {
    this._period_length_secs = value;
  }

  // position 1-based index in obligation
  private _position: number | null = null;
  get position(): number | null {
    return this._position;
  }
  set position(value: number | null) {
    this._position = value;
  }

  // position 1-based index in express route
  private _route_position: number | null = null;
  public get route_position(): number | null {
    return this._route_position;
  }
  public set route_position(value: number | null) {
    this._route_position = value;
  }

  private _route_part_length: number | null = null;
  get route_part_length(): number | null {
    return this._route_part_length;
  }
  set route_part_length(value: number | null) {
    this._route_part_length = value;
  }
  
  private _route_part_length_estimated: number | null = 0;
  public get route_part_length_estimated(): number | null {
    return this._route_part_length_estimated;
  }
  public set route_part_length_estimated(value: number | null) {
    this._route_part_length_estimated = value;
  }

  /*
   * "A" || "L" = Nakladka
   * "B" || "U" = Vykladka
   * "C" || "X" = Tranzit
   * "D" || "M" = Prejezd
   * "E" || "F" = Tankovani 
   */
  private _type: string| null = null;
  get type(): string | null {
    return this._type;
  }
  set type(value: string | null) {
    this._type = value;
    // synchronize to new version
    this.synchronizeItineraryType();

    // set type to string
    this._typeName = '';
    if (this._type == 'A' || this._type == 'L') this._typeName = 'Nakládka';
    else if (this._type == 'B' || this._type == 'U') this._typeName = 'Vykládka';
    else if (this._type == 'C' || this._type == 'X') this._typeName = 'Tranzit';
    else if (this._type == 'D' || this._type == 'M') this._typeName = 'Přejezd';
    else if (this._type == 'E' || this._type == 'F') this._typeName = 'Tankování';
    else if (this._type == 'W' || this._type == 'V') this._typeName = 'Sklad';
    else if (this._type == 'T') this._typeName = 'Překládka';
    else if (this._type == 'Z') this._typeName = 'Celnice';
  }

  // method for synchronizing itinerary types (OBSOLETE types)
  synchronizeItineraryType(): void {
    if (this.type == 'A') this.type = 'L';   // nakladka-load
    else if (this.type == 'B') this.type = 'U'; // vykladka-unload
    else if (this.type == 'C') this.type = 'X'; // tranzit
    else if (this.type == 'D') this.type = 'M'; // prejezd
    else if (this.type == 'E') this.type = 'F'; // tankovani
  }

  // background color according to itinerary type
  get backgroundType(): string {
    switch (this._type) {
      case 'L':
        return '#bde3c5';
      case 'U':
        return '#ffee7c';
      case 'X':
        return '#dedede';
      case 'M':
        return '#dedede';
      case 'F':
        return '#99caff';
      case 'W':
        return '#84d4c9';
      default:
        return '#fff'
    }
  }
  
  // custom dictionary
  private _typeName: string| null = null;
  get typeName(): string | null {
    return this._typeName;
  }
  
  private _use_motorway: boolean | null = null;
  get use_motorway(): boolean | null {
    return this._use_motorway;
  }
  set use_motorway(value: boolean | null) {
    this._use_motorway = value;
  }

  private _volume: number | null = null;
  get volume(): number | null {
    return this._volume;
  }
  set volume(value: number | null) {
    this._volume = value;
  }

  private _ware_pcs: number | null = null;
  get ware_pcs(): number | null {
    return this._ware_pcs;
  }
  set ware_pcs(value: number | null) {
    this._ware_pcs = value;
  }

  private _ware_type: string| null = null;
  get ware_type(): string | null {
    return this._ware_type;
  }
  set ware_type(value: string | null) {
    this._ware_type = value;
  }
  
  private _weight: number | null = null;
  get weight(): number | null {
    return this._weight;
  }
  set weight(value: number | null) {
    this._weight = value;
  }

  private _work_day_begin: string| null = null;
  get work_day_begin(): string | null {
    return this._work_day_begin;
  }
  set work_day_begin(value: string | null) {
    this._work_day_begin = value;
  }

  get work_day_begin_formatted(): string | null {
    if (this._work_day_begin && this._work_day_begin.length > 5) {
      return this._work_day_begin.substring(0,5);
    }
    return this._work_day_begin;
  }
  
  private _work_day_begin_minutes: number = 420;
  public get work_day_begin_minutes(): number {
    return this._work_day_begin_minutes;
  }
  public set work_day_begin_minutes(value: number) {
    this._work_day_begin_minutes = value;
  }

  private _work_day_end: string| null = null;
  get work_day_end(): string | null {
    return this._work_day_end;
  }
  set work_day_end(value: string | null) {
    this._work_day_end = value;
  }

  get work_day_end_formatted(): string | null {
    if (this._work_day_end && this._work_day_end.length > 5) {
      return this._work_day_end.substring(0,5);
    }
    return this._work_day_end;
  }
  
  private _work_day_end_minutes: number = 960;
  public get work_day_end_minutes(): number {
    return this._work_day_end_minutes;
  }
  public set work_day_end_minutes(value: number) {
    this._work_day_end_minutes = value;
  }

  private _marker: GoogleMapMarker | null = null;
  public get marker(): GoogleMapMarker | null {
    return this._marker;
  }
  public set marker(value: GoogleMapMarker | null) {
    this._marker = value;
  }

  private _warehouse_in_itinerary: boolean = false;
  public get warehouse_in_itinerary(): boolean {
    return this._warehouse_in_itinerary;
  }
  public set warehouse_in_itinerary(value: boolean) {
    this._warehouse_in_itinerary = value;
  }

  private _warehouse_out_itinerary: boolean = false;
  public get warehouse_out_itinerary(): boolean {
    return this._warehouse_out_itinerary;
  }
  public set warehouse_out_itinerary(value: boolean) {
    this._warehouse_out_itinerary = value;
  }
}